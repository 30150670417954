import axios from '@axios'

export default {
  namespaced: true,
  state: {
    ppaList: [],
  },
  getters: {
    getPpaList: state => state.ppaList,
  },
  mutations: {
    SET_PPA_LIST: (state, val) => {
      state.ppaList = val
    },
  },
  actions: {
    fetchGroupList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/groups', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupTypes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/group-types')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupTypesForSelect(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/group-types-for-select', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`auth/group-types/${queryParams.id}`)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    fetchParentGroup(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`auth/groups-by-type/${queryParams.id}`, {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSessions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/sessions', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupMemberList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/campers', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStaffList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/staffs', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCampersForSelectList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/campers-for-select', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllMemberList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/applications', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProgramList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/programs', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportCampers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/campers/export-with-group', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroupsCustomFieldsAttributes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('auth/group-custom-fields', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveBulkGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/save-bulk-groups', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    importBulkAssignGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/groups-bulk-assign', formData)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    importBulkCreateGroups(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/groups-bulk-create', formData)
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    exportGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/groups-export', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cloneGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/auth/groups/${data.group_id}/clone`, { copy_members: data.copy_members })
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    fetchApplicationsForGroupAutoAssign(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/applications-for-group-auto-assign', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    async fetchPpaList({ commit }, queryParams) {
      try {
        const response = await axios.get('/auth/ppa/names', {
          params: queryParams,
        })

        commit('SET_PPA_LIST', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
  },
}
