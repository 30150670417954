<template>
  <b-list-group-item
    tag="div"
    class="d-flex justify-content-start"
  >

    <!-- text rows-->
    <b-card-text
      v-if="fieldsData.type === 'step'"
    >
      <h2 class="mt-2">
        {{ fieldsData.label }}
      </h2>
    </b-card-text>

    <b-card-text
      v-else-if="fieldsData.type === 'plain_text'"
    >
      {{ fieldsData.label }}
    </b-card-text>

    <!-- fields rows-->
    <template
      v-else
    >

      <!-- Label -->
      <b-col
        md="3"
        xl="3"
      >
        <b-card-text>
          <span class="w-100 bv-no-focus-ring col-form-label pt-0">
            Field
          </span>
          <h5 class="mt-2">
            {{ fieldsData.label }}
          </h5>
        </b-card-text>
      </b-col>

      <!-- Input Name -->
      <b-col
        md="7"
        xl="7"
      >
        <b-form-group
          label="Result"
        >
          <ValidationProvider
            v-slot="{ errors, ariaInput }"
            :name="fieldsData.label"
            :rules="fieldsData.required ? 'required' : ''"
            :skip-if-empty="false"
          >
            <b-form-input
              v-if="showTextField(fieldsData.type)"
              v-model="fieldsData.value"
              :aria-input="ariaInput"
              :name="fieldsData.name"
              :type="fieldsData.type"
              :required="fieldsData.required"
              size="lg"
              @input="emitChanges"
            />

            <b-form-textarea
              v-if="fieldsData.type === 'long_text'"
              id="textarea-default"
              v-model="fieldsData.value"
              :aria-input="ariaInput"
              :name="fieldsData.name"
              :required="fieldsData.required"
              rows="3"
              @input="emitChanges"
            />

            <b-form-datepicker
              v-if="fieldsData.type === 'date'"
              id="example-datepicker"
              v-model="fieldsData.value"
              :aria-input="ariaInput"
              :required="fieldsData.required"
              class="mb-1"
              @input="emitChanges"
            />

            <b-form-select
              v-if="fieldsData.type === 'dropdown'"
              v-model="fieldsData.value"
              :options="fieldsData.options"
              :required="fieldsData.required"
              size="lg"
              @input="emitChanges"
            />

            <b-form-select
              v-if="fieldsData.type === 'multi_select'"
              v-model="fieldsData.value"
              :aria-input="ariaInput"
              :options="fieldsData.options"
              multiple
              :required="fieldsData.required"
              size="lg"
              @input="emitChanges"
            />

            <template
              v-if="fieldsData.type === 'checkbox'"
            >
              <b-form-checkbox-group
                v-model="fieldsData.value"
                :options="fieldsData.options"
                :aria-input="ariaInput"
                class="py-1"
                @input="emitChanges"
              />
            </template>

            <template
              v-if="fieldsData.type === 'radio_button'"
            >
              <b-form-radio-group
                v-model="fieldsData.value"
                :options="fieldsData.options"
                :aria-input="ariaInput"
                class="py-1"
                @input="emitChanges"
              />
            </template>

            <b-form-file
              v-if="fieldsData.type === 'file_upload'"
              v-model="fieldsData.value"
              :aria-input="ariaInput"
              multiple
              :required="fieldsData.required"
              size="lg"
              :file-name-formatter="formatNames"
              @input="emitChanges"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </template>
  </b-list-group-item>
</template>

<script>
import {
  BCol,
  BCardText,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BListGroupItem,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BFormFile,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import useProgramsSetting from '@/views/admin/program/useProgramsSetting'

import store from '@/store'
import storeModule from '@/views/admin/group/groupStoreModule'

export default {
  components: {
    BCol,
    BCardText,
    BFormGroup,
    BListGroupItem,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormFile,

    ValidationProvider,
  },
  props: {
    fieldsData: {
      type: Object,
      default: () => {},
    },
    fieldIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      formBuilderFieldTypes,
    } = useProgramsSetting()

    return {
      formBuilderFieldTypes,
    }
  },
  methods: {
    showTextField(type) {
      // eslint-disable-next-line no-bitwise
      return ~['text', 'email', 'tel', 'number'].findIndex(item => item === type)
    },
    formatNames(files) {
      if (files.length === 1) return files[0].name

      return `${files.length} files selected`
    },
    emitChanges() {
      this.$emit('influence', this.fieldsData.fieldInfluence.map(item => ([item.id, item.related_field])), this.fieldsData.value)
    },
  },
}
</script>

<style scoped>

</style>
